/* Navbar styling */
.navbar {
  width: 100%;
  color: rgb(0, 0, 0);
  font-size: 14px;
  position: fixed;
  top: 0;
  z-index: 999;
  background-color: #ebf2fa;
  transition: background-color 0.3s ease;

  &.scrolled {
    background-color: #00a9a5;
    color: #ebf2fa;
  }

  .container {
    padding: 0 20px; /* Reduced padding */
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px; /* Reduced height */

    .left {
      display: flex;
      align-items: center;

      img {
        width: 50px; /* Reduced logo size */
        margin-right: 10px; /* Reduced margin */
        transition: transform 0.3s ease;
      }
    }

    .center {
      flex-grow: 1;
      display: flex;
      justify-content: center;

      .links {
        display: flex;
        align-items: center;

        span {
          margin: 0 10px; /* Reduced margin */
          cursor: pointer;
          transition: color 0.3s ease; /* Added transition for text color change */
          position: relative;

          &:after {
            content: "";
            position: absolute;
            width: 100%;
            height: 2px;
            bottom: -3px;
            left: 0;
            background-color: transparent;
            transition: background-color 0.3s ease;
          }

          &:hover {
            color: #092327; /* Change text color on hover */
            &:after {
              background-color: #092327; /* Change underline color on hover */
            }
          }
        }
      }
    }

    .right {
      display: flex;
      align-items: center;

      .notification-icon,
      .account-icon {
        font-size: 24px; /* Reduced icon size */
        cursor: pointer;
        margin: 0 10px; /* Reduced margin */
        transition: transform 0.3s ease;
      }
    }
  }

  .language-dropdown {
    margin-right: 10px;

    select {
      padding: 5px;
      border-radius: 5px;
      border: 1px solid #ccc;
      background-color: #fff;
      font-size: 14px;
      cursor: pointer;
      transition: border-color 0.3s ease;

      &:focus {
        outline: none;
        border-color: #00a9a5;
      }
    }
  }
}

/* Animation for scrolling */
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.navbar.scrolled .container {
  animation: fadeInDown 0.5s ease;
}

/* Media Query for tablets */
@media screen and (max-width: 992px) {
  .navbar .container {
    padding: 0 15px; /* Further reduced padding */
  }

  .navbar .left img {
    width: 40px; /* Further reduced logo size */
  }

  .navbar .center .links span {
    margin: 0 8px; /* Further reduced margin */
  }

  .navbar .right .notification-icon,
  .navbar .right .account-icon {
    font-size: 20px; /* Further reduced icon size */
    margin: 0 8px; /* Further reduced margin */
  }
}
/* Media Query for mobile devices */
@media screen and (max-width: 768px) {
  .navbar .container {
    padding: 0 10px; /* Further reduced padding */
  }

  .navbar .left img {
    display: none; /* Hide logo on small screens */
  }

  .navbar .center .links span {
    margin: 0 5px; /* Further reduced margin */
  }

  .navbar .right .notification-icon,
  .navbar .right .account-icon {
    margin: 0 5px; /* Further reduced margin */
  }
}