body{
    background-color: #f2e9e4;
    width: auto;
}
.section2{
    display: flex;
    margin-top: 50px;
    height: 750px;
    width: 100%;
    overflow: hidden; /* Hide overflow to prevent scrollbars */
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    
     /* Animation for background image transition */
  animation: slideBackground 10s infinite alternate;

  @keyframes slideBackground {
    0%, 100% {
      background-image: url("../../image/adwa.png");
    }
    25% {
      background-image: url("../../image/ADWA\ Victory\ day\ 2011E_C.png");
    }
    50% {
      background-image: url("../../image/Ethiopian\ Warrior\ \,\ Biny\ Adenew.png");
    }
    75% {
      background-image: url("../../image/Ethiopian\ Warrior\ \,\ Biny\ Adenew.jpeg");
    }
    100% {
      background-image: url("../../image/Proverbs\ 21_31\ The\ horse\ is\ made\ ready\ for\ the\ day\ of\ battle\,\ but\ victory\ rests\ with\ the\ LORD_.png");
    }
  }

    h3 {
        margin-top: 180px;
        margin-left: 160px;
        color: white;
        font-size: 50px;

        span {
            color: white; // Start with white color
            animation: colorTransition 2s infinite alternate; // Animation for color transition
            opacity: 0; // Start with opacity 0
            animation-delay: 10s; // Delay for fading in
            animation-fill-mode: forwards; // Keep the last keyframe state
        }
    }

    p {
        color: aliceblue;
        margin-left: 140px;
        margin-bottom: 200px;
        width: 550px;
        opacity: 0; // Start with opacity 0
        animation: fadeInRight 2s forwards;
        animation-delay: 1s; // Delay for the fade-in animation
    }

    .logo {
        position: relative;
        margin-top: 170px;
        margin-left: 150px;
        width: 400px;
        height: 400px;
    }

    @keyframes colorTransition {
        0% {
            color: white;
            opacity: 0;
        }
        50% {
            color: #00a9a5;
            opacity: 1; // Fade in halfway through the animation
        }
        100% {
            color: white;
            opacity: 0;
        }
    }
    @keyframes fadeInRight {
        from {
            opacity: 0;
            transform: translateX(100%);
        }
        to {
            opacity: 1;
            transform: translateX(0);
        }
    }
}
.lateVidContent {
    font-size: 0; /* Remove inline-block whitespace gap */
  }
  
  .videoContainer1 {
    width: 270px; /* Set the width of each video container */
    height: 250px; /* Set the height of each video container */
    display: inline-block; /* Display video containers side by side */
    margin-right: 10px; /* Add some margin between video containers */
    margin-bottom: 10px; /* Add some bottom margin for spacing */
    vertical-align: top; /* Align video containers to the top */
  }
  
  .videoContainer1 video {
    width: 100%; /* Make the video fill the container */
    height: 70%; /* Adjust height for video */
  }
  
  .videoInfo {
    padding: 10px; /* Add padding for text content */
    background-color: #f4f4f4; /* Set background color for video info */
    height: 30%; /* Adjust height for video info */
    box-sizing: border-box; /* Include padding and border in total height */
  }
  
.catagory{
    
    height: 1000px;
    h2{
        position: absolute;
        margin-left: 25px;
        font-size: 50px;
        color: #00a9a5;
    }
    .cog{
        margin-top: 80px;
        display: flex;
        position: absolute;
       
        .video-placeholder {
            
            margin-right: 10px;
            margin-left: 10px;
            width: 270px;
            height: 200px; /* Adjust height as needed */
            background-color: black;
            cursor: pointer;
        }
       /* Base styles for button */
       button {
        margin-top: 70px;
        position: relative;
        opacity: 0;
        animation: fadeInButton 1.5s forwards;
        animation-delay: 1s;
        color: white;
        font-size: 18px;
        width: 180px;
        height: 60px; /* Increased height for a taller button */
        border: none;
        border-radius: 30px; /* Rounder shape */
        background-color: #00a9a5;
        transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s; /* Added box-shadow transition */
        cursor: pointer;
        overflow: hidden;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Added drop shadow */
    }
    
    button:hover {
        background-color: white;
        color: #00a9a5;
        box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2); /* Darker shadow on hover */
    }
    
    button span {
        position: relative;
        z-index: 1;
    }
    
    button::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 300%;
        height: 300%;
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 50%;
        transition: transform 0.6s ease-out;
        transform: translate(-50%, -50%) scale(0);
    }
    
    button:hover::before {
        transform: translate(-50%, -50%) scale(1);
    }
    
    @keyframes fadeInButton {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    

    
    }
    .phy{
        margin-top: 300px;
        display: flex;
        position: absolute;
        h2{
            position: absolute;
            margin-left: 25px;
            font-size: 50px;
            color: #00a9a5;
        }
        .video-placeholder {
            margin-top: 70px;
            margin-right: 10px;
            margin-left: 10px;
            width: 270px;
            height: 200px; /* Adjust height as needed */
            background-color: black;
            cursor: pointer;
        }
       /* Base styles for button */
       button {
        margin-top: 150px;
        position: relative;
        opacity: 0;
        animation: fadeInButton 1.5s forwards;
        animation-delay: 1s;
        color: white;
        font-size: 18px;
        width: 180px;
        height: 60px; /* Increased height for a taller button */
        border: none;
        border-radius: 30px; /* Rounder shape */
        background-color: #00a9a5;
        transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s; /* Added box-shadow transition */
        cursor: pointer;
        overflow: hidden;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Added drop shadow */
    }
    
    button:hover {
        background-color: white;
        color: #00a9a5;
        box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2); /* Darker shadow on hover */
    }
    
    button span {
        position: relative;
        z-index: 1;
    }
    
    button::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 300%;
        height: 300%;
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 50%;
        transition: transform 0.6s ease-out;
        transform: translate(-50%, -50%) scale(0);
    }
    
    button:hover::before {
        transform: translate(-50%, -50%) scale(1);
    }
    
    @keyframes fadeInButton {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

}
    .sir{
        margin-top: 600px;
        display: flex;
        position: absolute;
        h2{
            position: absolute;
            margin-left: 25px;
            font-size: 50px;
            color: #00a9a5;
        }
        .video-placeholder {
            margin-top: 70px;
            margin-right: 10px;
            margin-left: 10px;
            width: 270px;
            height: 200px; /* Adjust height as needed */
            background-color: black;
            cursor: pointer;
        }
       /* Base styles for button */
       button {
        margin-top: 150px;
        position: relative;
        opacity: 0;
        animation: fadeInButton 1.5s forwards;
        animation-delay: 1s;
        color: white;
        font-size: 18px;
        width: 180px;
        height: 60px; /* Increased height for a taller button */
        border: none;
        border-radius: 30px; /* Rounder shape */
        background-color: #00a9a5;
        transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s; /* Added box-shadow transition */
        cursor: pointer;
        overflow: hidden;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Added drop shadow */
    }
    
    button:hover {
        background-color: white;
        color: #00a9a5;
        box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2); /* Darker shadow on hover */
    }
    
    button span {
        position: relative;
        z-index: 1;
    }
    
    button::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 300%;
        height: 300%;
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 50%;
        transition: transform 0.6s ease-out;
        transform: translate(-50%, -50%) scale(0);
    }
    
    button:hover::before {
        transform: translate(-50%, -50%) scale(1);
    }
    
    @keyframes fadeInButton {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    

    
    }
}
.team-member-section {
    margin-top: 10px;
    text-align: center;
  
    h2 {
      font-size: 24px;
      margin-bottom: 20px;
    }
  
    .team-member-container {
      display: flex;
      justify-content: center;
      gap: 20px;
  
      .team-member-card {
        width: 200px;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        overflow: hidden;
        transition: transform 0.3s ease;
        cursor: pointer;
  
        &:hover {
          transform: translateY(-5px);
          box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
        }
  
        .image-container {
          img {
            width: 100%;
            height: auto;
            display: block;
            border-radius: 8px 8px 0 0;
          }
        }
  
        .info-container {
          padding: 10px;
  
          .name {
            font-size: 18px;
            margin-bottom: 5px;
          }
  
          .position {
            font-size: 14px;
            color: #666;
          }
        }
      }
    }
  }
.mid-section {
    margin-top: 20px;
    display: flex;
    height: 500px;
    position: relative;
    background-color: white;
    border-radius: 10%;
    border-top-right-radius: 5%;
    border-top-left-radius: 5%;

    .img2 {
        margin-top: 80px;
        margin-left: 300px;
        width: 300px;
        height: 300px;
        opacity: 0; // Start with opacity 0
        animation: fadeInBottom 2s forwards;
        animation-delay: 1s; // Delay for the fade-in animation
    }

    .p {
        margin-left: 140px;
        width: 550px;

        h3 {
            font-size: 50px;
            color:  #00a9a5;
            text-decoration: underline;
            opacity: 0; // Start with opacity 0
            animation: fadeInRightH3 1.5s forwards; // Different duration for h3 fade-in animation
            animation-delay: 0.5s;
            align-items: center; // Delay for the fade-in animation
        }

        p {
            font-size: 17px;
            margin-bottom: 50px;
            opacity: 0; // Start with opacity 0
            animation: fadeInRightP 1.5s forwards; // Different duration for p fade-in animation
            animation-delay: 1s;
            color:  #00a9a5; // Delay for the fade-in animation
        }
    }

    a {
        position: relative;
        opacity: 0; // Start with opacity 0
        animation: fadeInButton 1.5s forwards; // Animation for button fade-in
        animation-delay: 1s;
        display: flex;
        justify-content: center; // Center the button horizontally
        align-items: center;
       
    }

    a button {
        align-items: center;
        color: white;
        font-size: 18px;
        width: 180px;
        height: 40px;
        border-radius: 5px;
        background-color: #00a9a5;
        transition: all 0.3s;
    }

    a button:hover {
        background-color: #000000;
    }

    

    @keyframes fadeInBottom {
        from {
            opacity: 0;
            transform: translateY(100%);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @keyframes fadeInRightH3 {
        from {
            opacity: 0;
            transform: translateX(100%);
        }
        to {
            opacity: 1;
            transform: translateX(0);
        }
    }

    @keyframes fadeInRightP {
        from {
            opacity: 0;
            transform: translateX(100%);
        }
        to {
            opacity: 1;
            transform: translateX(0);
        }
    }
    @keyframes fadeInButton {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
}
.sec_mid{
    height: 700px;

}
.title{
    border-radius: 10%;
    position: relative;
    height: 500px;
    background-image: url("../../image/ADWA\ Victory\ day\ 2011E_C.png");
   background-attachment: fixed;
   background-position: center;
   background-size: cover;
    color: wheat;
    text-align: center;
    font-size: 150px;
    h2{
        font-size: 60px;
        padding-top: 90px;
    }
}
.experts {
    height: 300px;
    margin-top: 50px;
    display: flex;
}

.mental, .physical, .spirit {
    margin-left: 50px;
    position: relative;
    width: 400px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center vertically */
    align-items: center; /* Center horizontally */
    background-color: white; /* Set background color to white */
    border-radius: 20px; /* Set border radius to 20px */
    border: 2px solid #00a9a5;
    transition: all 0.8s ease; /* Add transition for smooth hover effect */
}

.mental:hover, .physical:hover, .spirit:hover {
    color: wheat;
    background-color: #00a9a5;
    transform: translateY(20px); /* Move down on hover */
}

.mental {
}

.physical {
    margin-left: 10px;
}

.spirit {
    margin-left: 10px;
}

.lottie {
    width: 250px;
}

h2 {
    font-family: 'Times New Roman', Times, serif;
    font-size: 25px;
    text-align: center;
    margin: 0; /* Remove default margin */
}

/* Animation */
@keyframes fadeDown {
    from {
        opacity: 0;
        transform: translateY(-250px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.mental, .physical, .spirit {
    animation: fadeDown 2s e; /* Apply animation */
}

.physical {
    animation-delay: 0.5s; /* Delay animation */
}

.spirit {
    animation-delay: 1s; /* Delay animation */
}
.bon1{
a {
    margin-top: 50px;
    position: relative;
    opacity: 0; // Start with opacity 0
    animation: fadeInButton 1.5s forwards; // Animation for button fade-in
    animation-delay: 1s;
    display: flex;
    justify-content: center; // Center the button horizontally
    align-items: center;
}

a button {
    align-items: center;
    color: white;
    font-size: 18px;
    width: 180px;
    height: 40px;
    border-radius: 5px;
    background-color: #00a9a5;
    transition: all 0.3s;
}

a button:hover {
    background-color: white;
    color: #00a9a5;
}
}
.btn2{
    a {
        bottom: 100px;
        position: relative;
        opacity: 0; // Start with opacity 0
        animation: fadeInButton 1.5s forwards; // Animation for button fade-in
        animation-delay: 1s;
        display: flex;
        justify-content: center; // Center the button horizontally
        align-items: center;
    }
    
    a button {
        align-items: center;
        color: white;
        font-size: 18px;
        width: 180px;
        height: 40px;
        border-radius: 5px;
        background-color: #00a9a5;
        transition: all 0.3s;
    }
    
    a button:hover {
        background-color: white;
        color: #00a9a5;
    }
}

@keyframes fadeInBottom {
    from {
        opacity: 0;
        transform: translateY(100%);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
h1{
    font-size: 50px;
    margin-left: 550px;
    align-items: center;
    color: #00a9a5;
    text-decoration: underline;
    
}
.docs {
    display: flex;
    margin-top: 50px;
    margin-left: 50px;
    height: 600px;
}

.docs .d1,
.docs .d2,
.docs .d3,
.docs .d4 {
    background-size: cover;
    margin-left: 10px;
    width: 300px;
    height: 450px;
    background-color: aqua;
    transition: all 0.8s ease; /* Add transition for smooth hover effect */
}



.docs .d1 {
    background-image: url("/image/gtAiQ2YSg8a3HcrMfPeN21JC9GRgCopJ.jpg");
    animation: fadeDown 1.5s ease; /* Apply animation */
    animation-delay: 0.5s; /* Delay animation */
}

.docs .d2 {
    background-image: url("/image/TztHCfQxljmwCM9y8S8ld04igm4M6oOth8dw5MJd.jpg");
    animation: fadeDown 1.5s ease; /* Apply animation */
    animation-delay: 1s; /* Delay animation */
}

.docs .d3 {
    background-image: url("/image/HZrwftmjNfoOoDx3OTZIYSlky0TLE6nZ.jpg");
    animation: fadeDown 1.5s ease; /* Apply animation */
    animation-delay: 1.5s; /* Delay animation */
}

.docs .d4 {
    background-image: url("/image/ov3DM4RrGjhzmzd0pco48PBRqTSRO955.jpg");
    animation: fadeDown 1.5s ease; /* Apply animation */
    animation-delay: 2s; /* Delay animation */
}

.docs h2 {
    margin-top: 420px;
}

/* Animation */
@keyframes fadeup {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
.blogContent{
    margin-top: 200px;
}
/* Media Queries for Responsive Design */
@media screen and (max-width: 768px) {
    /* Adjust styles for smaller screens */
    /* Example: */
    .title h2 {
        font-size: 40px; /* Decrease font size for smaller screens */
    }
}

@media screen and (max-width: 576px) {
    /* Adjust styles for even smaller screens */
    /* Example: */
    .section2 {
        height: 500px; /* Decrease height for smaller screens */
    }
}