.doc{
    


.title{
    position: relative;
    height: 250px;
    background-image: url("/image/multi-ethnic-personnel-treats-patient-hospital-ward.jpg");
   background-attachment: fixed;
   background-position: center;
   background-size: cover;
    color: wheat;
    text-align: center;
    font-size: 150px;
    h2{
        font-size: 60px;
        padding-top: 120px;
    }
}
.mel1{
   h3{
    text-align: center;
    font-size: 45px;
    color: #003459;
   }
   p{
    padding-bottom: 20px;
    margin-left: 250px;
    position: relative;
    text-align: center;
    width: 800px;
   }
}
.mel2{
    h3{
     text-align: center;
     font-size: 45px;
     color: #003459;
    }
    p{
        padding-bottom: 20px;
     margin-left: 250px;
     position: relative;
     text-align: center;
     width: 800px;
    }
 }
 .mel3{
    h3{
     text-align: center;
     font-size: 45px;
     color: #003459;
    }
    p{
        padding-bottom: 20px;
     margin-left: 250px;
     position: relative;
     text-align: center;
     width: 800px;
    }
 }
}