// category.scss

// Global styles
body {
    font-family: Arial, sans-serif;
  }
  
  // Styles for Category component
  .category-page {
    padding: 20px;
  
    .page-title {
      font-size: 24px;
      margin-bottom: 20px;
    }
  
    .category-list {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      gap: 20px;
  
      .category-item {
        background-color: #f0f0f0;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s ease-in-out;
  
        &:hover {
          transform: translateY(-5px);
        }
  
        .category-image {
          margin-bottom: 10px;
  
          iframe {
            border-radius: 8px;
          }
        }
  
        h3 {
          margin-bottom: 10px;
          font-size: 18px;
          color: #333;
        }
  
        .category-content {
          p {
            margin-bottom: 10px;
            color: #0b5351;
          }
  
          .category-tags {
            margin-bottom: 10px;
  
            .tag {
              margin-right: 5px;
              padding: 5px 10px;
              border-radius: 5px;
              background-color: #ccc;
              color: #0b5351;
            }
          }
  
          .category-rating {
            color: #00a9a5;
          }
  
          .btn-watch,
          .more-details-link {
            display: inline-block;
            margin-right: 10px;
            margin-bottom: 10px;
            padding: 8px 16px;
            border: none;
            border-radius: 5px;
            background-color: #00a9a5;
            color: #fff;
            text-decoration: none;
            cursor: pointer;
  
            &:hover {
              background-color: white;
              color: #00a9a5;
            }
          }
        }
      }
    }
  }
  
  // Styles for CategoryListPage component
  .category-list-page {
    padding: 20px;
  
    h1 {
      font-size: 24px;
      margin-bottom: 20px;
    }
  
    .video-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
  
      .video-item {
        width: calc(25% - 20px); /* Adjust the width as needed */
        margin-bottom: 20px;
  
        .video-thumbnail {
          width: 100%;
          padding-bottom: 56.25%; /* Maintain aspect ratio (16:9) for iframe */
          position: relative;
  
          iframe {
            border-radius: 8px;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
          }
        }
  
        .video-details {
          padding: 10px;
          background-color: #f0f0f0;
          border-radius: 8px;
  
          h3 {
            margin-bottom: 5px;
            font-size: 18px;
            color: #333;
          }
  
          p {
            font-size: 14px;
            color: #666;
          }
        }
      }
    }
    // Media query for responsiveness
@media screen and (max-width: 768px) {
  .category-page .category-list {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  .category-list-page .video-item {
      width: calc(50% - 20px); /* Adjust the width for smaller screens */
  }
}
  }