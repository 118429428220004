.bloge {
  max-width: 800px;
  margin-top: 40px;
  padding: 20px;
  font-family: Arial, sans-serif;
  
  h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
    
    li {
      margin-bottom: 30px;
      border-bottom: 1px solid #ccc;
      
      h2 {
        font-size: 1.8rem;
        margin-bottom: 10px;
        color: #333;
      }
      
      p {
        color: #666;
        margin-bottom: 5px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    h1 {
      font-size: 1.5rem; /* Further reduced font size for smaller screens */
    }

    ul li {
      margin-bottom: 15px; /* Further reduced margin for smaller screens */
    }

    ul li h2 {
      font-size: 1.3rem; /* Further reduced font size for smaller screens */
    }

    ul li p {
      font-size: 0.9rem; /* Further reduced font size for smaller screens */
    }
  }
}
