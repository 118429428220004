.listItem {
  width: 225px;
  height: 420px;
  background-color: var(--main-color);
  margin-right: 5px;
  overflow: hidden;
  cursor: pointer;
  color: white;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .listItemContent {
    /* Optional: Set a default background color or opacity */
    opacity: 1; /* Initially visible */
    transition: opacity 0.3s ease; /* Smooth fade on hover */
  }

  .visitButton {
    opacity: 0; // Initially hidden with opacity
    transition: opacity 0.3s ease; // Add transition for smooth appearance

    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    color: white; /* Button text color */
    background-color: blue; /* Button background color */
    padding: 10px 20px; /* Button padding */
    border: none; /* Remove button border */
    border-radius: 5px; /* Button border radius */
    cursor: pointer; /* Cursor style */
    font-size: 16px; /* Button font size */
  }

  &:hover .listItemContent {
    opacity: 0.8; /* Slightly transparent on hover (optional) */
  }

  &:hover .visitButton {
    opacity: 1; // Show the button smoothly when listItem is hovered
  }
}
