.login {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 100%
    ),
    url("../../image/Ethiopian\ Warrior\ \,\ Biny\ Adenew.jpeg");
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;

  .top {
    .wrapper {
      padding: 20px 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .logo {
        height: 100px;
      }
    }
  }

  .container {
    width: 100%;
    height: 100%;
    position: absolute;
    margin-left: 10px;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;

    form {
      width: 300px;
      padding: 30px;
      border-radius: 10px; /* Increased border radius for a rounded look */
      background-color: rgba(0, 0, 0, 0.8); /* Increased opacity for better contrast */
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px; /* Added gap between form elements */

      h4 {
        text-align: center;
        font-size: 35px;
        text-decoration: underline;
        font-family: 'Times New Roman', Times, serif;
        color: antiquewhite;
        margin-bottom: 20px; /* Added margin bottom for spacing */
      }

      input {
        height: 40px;
        border-radius: 5px;
        background-color: rgb(255, 255, 255);
        color: rgb(0, 0, 0);
        padding-left: 10px;
        font-family: 'Times New Roman', Times, serif;
        width: 100%; /* Full width input */
        &::placeholder {
          color: rgb(0, 0, 0);
        }
      }

      button {
        height: 40px;
        border-radius: 5px;
        background-color: #0b5351;
        color: white;
        border: none;
        font-size: 18px;
        font-weight: 500;
        cursor: pointer;
        width: 100%; /* Full width button */
        transition: background-color 0.3s ease; /* Added transition effect */
        &:hover {
          background-color: #0a4441; /* Darken button on hover */
        }
      }

      span {
        text-align: center;
        color: lightgray;

        b {
          color: white;
        }
      }

      small {
        text-align: center;
        color: lightgray;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .container form {
    width: 80%; /* Reduce form width for smaller screens */
  }
}

@media screen and (max-width: 576px) {
  .container form {
    width: 90%; /* Further reduce form width for even smaller screens */
  }
}