/* Footer styling */
$color:#575654;
$color1:#212529;
.footer {
  
  margin-top: 700px;
 // background: linear-gradient(to Bottom,#212529, $color); 
  background-color: #00a9a5; /* Black color with 50% opacity */
  color: white; /* Text color */
  padding: 60px 0; /* Padding */

  img{
    margin-top: 50px;
    height: 250px;
  }
}


/* Container styling */
.footer .container {
  padding-right: 130px;
  max-width: 1200px; /* Maximum width of the container */
  /* Center the container */
}

/* Row styling */
.footer .row {
  display: flex; /* Use flexbox for row layout */
  flex-wrap: wrap;
  margin-left: 150px; /* Allow wrapping of columns */
}

/* Column styling */
.footer .footer-col {
  flex: 1; /* Equal width for each column */
  padding: 0 20px; /* Add spacing between columns */
}

/* Styling for headings inside footer */
.footer .footer-col h4 {
  font-size: 35px; /* Font size */
  margin-bottom: 10px; /* Bottom margin */
  margin-left: 30px;
}

/* Styling for paragraphs inside footer */
.footer .footer-col p {
  font-size: 14px; /* Font size */
  line-height: 1.0; /* Line height */
}

/* Styling for unordered lists inside footer */
.footer .footer-col ul {
  list-style: none; /* Remove default list style */
  margin-left: 30; /* Remove default padding */
}

/* Styling for list items inside footer */
.footer .footer-col ul li {
  margin-bottom: 5px; /* Bottom margin */
}

/* Styling for links inside footer */
.footer .footer-col ul li a {
  color: white; /* Text color */
  text-decoration: none; /* Remove default underline */
}

/* Styling for links on hover */
.footer .footer-col ul li a:hover {
  text-decoration: underline; /* Add underline on hover */
}
/* Media Query for tablets */
@media screen and (max-width: 992px) {
  .footer .footer-col {
    flex-basis: calc(50% - 20px); /* 2 columns with margin in between */
    padding: 0; /* Remove padding for tablets */
  }
}

/* Media Query for mobile devices */
@media screen and (max-width: 768px) {
  .footer .footer-col {
    flex-basis: 100%; /* Full width for each column */
    padding: 0; /* Remove padding for mobile devices */
  }

  .footer img {
    margin-top: 10px; /* Further reduced margin for smaller screens */
    height: 150px; /* Further adjusted height for smaller screens */
  }
}